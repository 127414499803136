<template>
  <b-row>
    <b-col cols="12">
      <NksxTableBasic></NksxTableBasic>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'
import NksxTableBasic from "../../../table/vue-good-table/NksxTableBasic";
export default {
  name: "Nksx",
  components: {
    NksxTableBasic,
    BRow, BCol
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>